import merge from 'lodash/merge';
import { createSlice } from '@reduxjs/toolkit';

export const checkoutPageInitialState = {
  couponCode: '',
};

export const checkoutSlice = createSlice({
  name: 'checkout-page',
  initialState: checkoutPageInitialState,
  reducers: {
    setCouponCode: (state, action) => {
      state.couponCode = action.payload;
    },
    clearCouponCode: state => {
      merge(state, { ...checkoutPageInitialState });
    },
  },
});

export const { 
    setCouponCode,
    clearCouponCode,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;