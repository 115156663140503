import React from "react";
import { Container } from "reactstrap";

const ProfilePageHeader = () => (
  <>
    <div className="page-header clear-filter page-header-small" filter-color="blue">
      <div
        className="page-header-image"
        style={{
          backgroundImage:
            "url(" + require("assets/img/wedding.jpeg").default + ")",
        }}
      ></div>
      <Container>
        {/* <div className="photo-container">
          <img alt="..." src={require("assets/img/ryan.jpg").default}></img>
        </div> */}
        <h3 className="title" style={{fontSize: 100}}>מחירון ״לאירוע״</h3>
        {/* <p className="category">Photographer</p> */}
        {/* <div className="content">
          <div className="social-description">
            <h2>26</h2>
            <p>Comments</p>
          </div>
          <div className="social-description">
            <h2>26</h2>
            <p>Comments</p>
          </div>
          <div className="social-description">
            <h2>48</h2>
            <p>Bookmarks</p>
          </div>
        </div> */}
      </Container>
    </div>
  </>
);

export default ProfilePageHeader;
