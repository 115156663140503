import ReactDOM from 'react-dom';
import GA4React from 'ga-4-react';
import { Provider } from 'react-redux';
import React, { useEffect } from 'react';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom';

import NucleoIcons from 'views/NucleoIcons';
import LoginPage from 'views/examples/LoginPage';
import ProfilePage from 'views/examples/ProfilePage';

import IndexPage from 'views/Index';
import ContactPage from 'views/contact';
import PricingPage from 'views/pricing';
import CheckoutPage from 'views/checkout';
import InvitationsPage from 'views/invitations';

import store from './store';

import 'assets/css/bootstrap.min.css';
import 'assets/scss/now-ui-kit.scss';

const persistor = persistStore(store);

(async function initGA() {
  try {
    window.ga4 = await new GA4React('G-CYD4L57NLP').initialize();
  } catch(e) {}
})();

const Wrapper = ({ children }) => {
  const location = useLocation();
  useEffect(function trackPageViewGA() {
    if (process.env.NODE_ENV !== 'development' && window.ga4) {
      window.ga4.pageview(`${location.pathname}${location.search}`);
    }
  }, [location]);
  return <>{children}</>;
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        <BrowserRouter>
            <Wrapper>
              <Switch>
                  <Route exact path='/' component={IndexPage} />
                  <Route path='/invitations/:invitationCategory' component={InvitationsPage} />
                  <Route path='/nucleo-icons' component={NucleoIcons} />
                  <Route exact path='/pricing' component={PricingPage} />
                  <Route exact path='/contact' component={ContactPage} />
                  <Route exact path='/checkout' component={CheckoutPage} />
                  <Route path='/profile-page' component={ProfilePage} />
                  <Route path='/login-page' component={LoginPage} />
                  <Redirect to='/' />
              </Switch>
            </Wrapper>
        </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
