import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import { configureStore, combineReducers } from '@reduxjs/toolkit';

import checkoutPageReducer from './features/checkout-page';
import invitationsPageReducer from './features/invitations-page';

const reducers = combineReducers({
  checkoutPage: checkoutPageReducer,
  invitationsPage: invitationsPageReducer,
});

const persistConfig = { key: 'root', storage };

const persistedReducer = persistReducer(persistConfig, reducers)

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
});