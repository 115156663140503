import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from 'reactstrap';

import logo from "assets/img/laerua-logo.svg";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = useState('navbar-transparent');
  const [collapseOpen, setCollapseOpen] = useState(false);

  const updateNavbarColor = useCallback(debounce(() => {
    if (document.documentElement.scrollTop > 120 || document.body.scrollTop > 120) {
      setNavbarColor('');
    } else {
      setNavbarColor('navbar-transparent');
    }
  }, 60), []);

  useEffect(function onScroll() {
    window.addEventListener('scroll', updateNavbarColor);
    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  }, []);

  return (
    <>
      {collapseOpen && (
        <div
          id='bodyClick'
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setCollapseOpen(false);
          }}
        />
      )}
      <Navbar className={`fixed-top ${navbarColor}`} expand='lg' color='info'>
        <Container>
          <div className='navbar-translate'>
            <Link to="/">
              <NavbarBrand href='/' title="Laerua" id='navbar-brand'>
                <img src={logo} title="Laerua" alt="Laerua" />
              </NavbarBrand>

              <UncontrolledTooltip target='#navbar-brand'>מעבר לדף הראשי</UncontrolledTooltip>
            </Link>

            <button
              type='button'
              aria-expanded={collapseOpen}
              className='navbar-toggler navbar-toggler'
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setCollapseOpen(!collapseOpen);
              }}
            >
              <span className='navbar-toggler-bar top-bar' />
              <span className='navbar-toggler-bar middle-bar' />
              <span className='navbar-toggler-bar bottom-bar' />
            </button>
          </div>

          <Collapse navbar isOpen={collapseOpen} className='justify-content-end'>
            <Nav navbar>
              {/* <UncontrolledDropdown nav>
                <DropdownToggle
                  caret nav
                  href='#pablo'
                  color='default'
                  onClick={(e) => e.preventDefault()}
                >
                  <p>הזמנות</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to='/' tag={Link}>
                    <i className='now-ui-icons business_chart-pie-36 mr-1' />
                    חתונה
                  </DropdownItem>
                  <DropdownItem href='/' target='_blank'>
                    <i className='now-ui-icons design_bullet-list-67 mr-1' />
                    בר מצווה
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}

              <NavItem>
                <Link to="/invitations/wedding">
                  <NavLink href='/invitations/wedding'>
                    <p>הזמנות</p>
                  </NavLink>
                </Link>
              </NavItem>

              <NavItem>
                <Link to="/pricing">
                  <NavLink href='/pricing'>
                    <p>מחירון</p>
                  </NavLink>
                </Link>
              </NavItem>

              <NavItem>
                <Link to="/contact">
                  <NavLink href='/contact'>
                    <p>יצירת קשר</p>
                  </NavLink>
                </Link>
              </NavItem>

              <NavItem>
                <Link to="/invitations/wedding">
                  <Button
                    color='info'
                    target='_blank'
                    id='upgrade-to-pro'
                    href='/invitations/wedding'
                    className='nav-link btn-neutral btn-round'
                  >
                    <p>עצבו הזמנה <small style={{fontSize:16}} className="mr-1">🎉</small></p>
                  </Button>
                  <UncontrolledTooltip target='#upgrade-to-pro'>
                    בחרו תבנית עיצוב ושיר, הכניסו פרטים ויש לכם הזמנה דיגיטלית!
                  </UncontrolledTooltip>
                </Link>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  href='https://twitter.com/CreativeTim?ref=creativetim'
                  target='_blank'
                  id='twitter-tooltip'
                >
                  <i className='fab fa-twitter'></i>
                  <p className='d-lg-none d-xl-none'>Twitter</p>
                </NavLink>
                <UncontrolledTooltip target='#twitter-tooltip'>
                  Follow us on Twitter
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href='https://www.facebook.com/CreativeTim?ref=creativetim'
                  target='_blank'
                  id='facebook-tooltip'
                >
                  <i className='fab fa-facebook-square'></i>
                  <p className='d-lg-none d-xl-none'>Facebook</p>
                </NavLink>
                <UncontrolledTooltip target='#facebook-tooltip'>
                  Like us on Facebook
                </UncontrolledTooltip>
              </NavItem>
              <NavItem> */}
                {/* <NavLink
                  href='https://www.instagram.com/CreativeTimOfficial?ref=creativetim'
                  target='_blank'
                  id='instagram-tooltip'
                >
                  <i className='fab fa-instagram'></i>
                  <p className='d-lg-none d-xl-none'>Instagram</p>
                </NavLink>
                <UncontrolledTooltip target='#instagram-tooltip'>
                  Follow us on Instagram
                </UncontrolledTooltip>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
