import React from 'react';
import values from 'lodash/values';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import iphone from "assets/img/iphone.png";
import fullLogo from "assets/img/laerua-full-logo.svg";

const gifConfig = require('assets/gif/config.json');

const IndexHeader = () => (
  <div className='page-header clear-filter' filter-color='blue'>
    <div className='page-header-image' style={{ backgroundImage: `url(${require('assets/img/bg-min.jpeg').default})` }} />

    <Container>
      <div className='content-center brand'>
        <img alt='Laerua Logo' className='n-logo' src={fullLogo} />
        <h1 className='h1-seo'>הזמנה דיגיטלית לאירוע שלכם</h1>
        <h2 className="h2-seo">צרו הזמנת וידאו מעוצבת ומרגשת עם שיר לבחירתכם כדי לשדרג את האירוע שלכם מהרגע הראשון.</h2>
      </div>

      <div className='content-center buttons'>
        <Link to="/invitations/wedding">
          <button className='btn-lg btn btn-round btn-outline-info'>
            בואו נתחיל
            <i className="now-ui-icons gestures_tap-01 mr-2" />
          </button>
        </Link>
      </div>

      <div className='content-center'>
        <a href="#how-it-works" title="how it works" className="how-it-works">
          איך זה עובד? 
        </a>
      </div>

      <div className='content-center videos'>
        {values(gifConfig.wedding).map(({ id, name }) => (
          <div className='video' key={`gif_${name}`}>
            <img 
              src={iphone} 
              alt="iphone" 
              title="iphone" 
              className="video-iphone"
            />
            <img 
              alt="invitation" 
              title="invitation" 
              className="video-invitation" 
              src={require(`assets/gif/wedding/${id}.gif`).default} 
            />
          </div>
        ))}
      </div>

      {/* <div className='content-center videos'>
        <div className='video'>
          <img 
            src={iphone} 
            alt="iphone" 
            title="iphone" 
            className="video-iphone"
          />
          <img 
            src={gifs[gifIndex]} 
            alt="invitation" 
            title="invitation" 
            className="video-invitation" 
          />
        </div>
      </div> */}

      {/* <video width="265" height="510" autoPlay="1" loop="1" muted="1" src={video}>
        <source src={video} type="video/mp4" />
        <source src={video} type="video/ogg" />
        Your browser does not support the video tag.
      </video> */}

      {/* <h6 className='category category-absolute'>
        Designed by{' '}
        <a href='http://invisionapp.com/?ref=creativetim' target='_blank'>
          <imgs
            alt='...'
            className='invision-logo'
            src={require('assets/img/invision-white-slim.png').default}
          />
        </a>
        . Coded by{' '}
        <a
          href='https://www.creative-tim.com?ref=nukr-index-header'
          target='_blank'
        >
          <img
            alt='...'
            className='creative-tim-logo'
            src={require('assets/img/creative-tim-white-slim2.png').default}
          ></img>
        </a>
        .
      </h6> */}
      
    </Container>
  </div>
);

export default IndexHeader;
