import { Helmet } from 'react-helmet';
import React, { useEffect } from 'react';

import IndexNavbar from 'components/Navbars/IndexNavbar';
import DefaultFooter from 'components/Footers/DefaultFooter';

import fullLogo from "assets/img/laerua-full-logo.svg";

const Layout = ({ children }) => {
  useEffect(() => {
    document.body.classList.add('index-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('index-page');
      document.body.classList.remove('sidebar-collapse');
    };
  });

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:title" content="לאירוע - הזמנות דיגיטליות מעוצבות לאירועים" />
          <meta property="og:description" content="צרו הזמנה דיגיטלית לאירוע שלכם. בחרו תבנית וידאו ושיר, מלאו פרטים, וההזמנה אצלכם בוואטספ." />
          <meta property="og:image" content={`https://laerua.co.il${fullLogo}`} />
          <meta property="og:url" content="https://laerua.co.il" />
          <meta name="twitter:title" content="לאירוע - הזמנות דיגיטליות מעוצבות לאירועים" />
          <meta name="twitter:description" content="צרו הזמנה דיגיטלית לאירוע שלכם. בחרו תבנית וידאו ושיר, מלאו פרטים, וההזמנה אצלכם בוואטספ." />
          <meta name="twitter:image" content={`https://laerua.co.il${fullLogo}`} />
          <meta name="twitter:card" content={`https://laerua.co.il${fullLogo}`} />
          <meta name="keywords" content="אירועים, הזמנות דיגיטליות, הזמנות, הזמנות לאירועים, הזמנות מרגשות, חתונה, בר מצווה, מסיבת רווקים, מסיבת רווקות, הזמנה למסיבה, הזמנה לחתונה, הזמנות זולות לחתונות" />
          <meta name="author" content="פאר תוכנה שיווק ופיננסים" />
          <meta name="publisher" content="פאר תוכנה שיווק ופיננסים" />
          <meta name="copyright" content="פאר תוכנה שיווק ופיננסים" />
          <meta name="description" content="יצירת הזמנה דיגיטלית לאירועים" />
          <meta name="page-topic" content="Digital video invitations for events - Wedding, Bar-Mitzva, Birthday..." />
          <meta name="page-type" content="Event Services" />
          <meta name="audience" content="Everyone" />
          <meta name="robots" content="index, follow" />
      </Helmet>
      <IndexNavbar />
      {children}
      <DefaultFooter />
    </>
  );
};

export default Layout;
