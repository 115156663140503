import { 
    Facebook, 
    Twitter, 
    Linkedin, 
    Pinterest, 
    Reddit, 
    Tumblr, 
    Whatsapp, 
    Telegram, 
    Mail,
} from 'react-social-sharing';

const link = 'https://laerua.co.il';
const message = '״לאירוע״ - הזמנות דיגיטליות מעוצבות לאירועים';

const isWindowSmall = window.innerWidth && (window.innerWidth < 650);

const SocialShare = () => (
    <div className="social-share">
        <Facebook solid small message={message} link={link} />
        <Twitter solid small message={message} link={link} />
        <Linkedin solid small message={message} link={link} />
        <Pinterest solid small message={message} link={link} />
        <Whatsapp solid small message={message} link={link} />
        <Telegram solid small message={message} link={link} />
        {!isWindowSmall && (
            <Tumblr solid small message={message} link={link} />
        )}
        {!isWindowSmall && (
            <Reddit solid small message={message} link={link} />
        )}
        {!isWindowSmall && (
            <Mail solid small message={message} link={link} />
        )}
  </div>
);

export default SocialShare;