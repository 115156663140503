import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import { AiOutlineInstagram, AiOutlineFacebook } from 'react-icons/ai';
// core components

function Download() {
  return (
    <>
      <div id="download-section" className="section section-download" data-background-color="black">
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h3 className="title mb-0 mt-2">אודות ״לאירוע - הזמנות דיגיטליות לאירועים״</h3>
              <h5 className="description mb-4" style={{fontSize: 22}}>
                בשנים האחרונות הפכה האופציה של שליחת הזמנה דיגיטלית לוואטספ (או לכל אפליקציית מסרים אחרת) לדרך המרכזית, החסכונית והירוקה להזמין אורחים לאירוע.
                אנו ב״לאירוע״, מאמינים שכך, ניתן לא רק לחסוך התעסקות מיותרת בדפים, מכתבים ומסירת הזמנות, אלא גם ליצור אפקט של תחושת רגש אמיתי להזמנה באמצעות
                עיצוב דינמי ומקצועי ושיר נוגע ללב.
              </h5>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <Button
                size="lg"
                color="info"
                role="button"
                style={{fontSize: 20}}
                className="btn-round ml-2"
                href="/invitations/wedding"
              >
                עיצוב הזמנה
              </Button>
              <Button 
                role="button"
                target="_blank"
                color="primary"
                href="/contact"
                outline size="lg"
                style={{fontSize: 20}}
                className="btn-round mr-2"
              >
                יצירת קשר
              </Button>
            </Col>
          </Row>
          {/* <Row className="text-center mt-5">
            <Col className="ml-auto mr-auto" md="8">
              <h2>Want more?</h2>
              <h5 className="description">
                We're going to launch{" "}
                <a
                  href="http://demos.creative-tim.com/now-ui-kit-pro-react/#/presentation?ref=nukr-index-page"
                  onClick={(e) => e.preventDefault()}
                >
                  Now UI Kit PRO React
                </a>
                . It will have huge number of components, sections and example
                pages so you can start your development with a badass Bootstrap
                4 UI Kit.
              </h5>
            </Col>
            <Col md="12">
              <Button
                className="btn-neutral btn-round"
                color="default"
                href="http://creative-tim.com/product/now-ui-kit-pro-react?ref=nukr-index-page"
                size="lg"
                target="_blank"
              >
                <i className="now-ui-icons arrows-1_share-66 mr-1"></i>
                Upgrade to PRO
              </Button>
            </Col>
          </Row> */}

          {/* <Row className="justify-content-md-center sharing-area text-center">
            <Col className="text-center" lg="8" md="12">
              <h3>אנחנו גם במדיה הדיגיטלית</h3>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <Button
                className="btn-neutral btn-icon btn-round"
                color="facebook"
                href="/"
                id="tooltip735272548"
                size="lg"
                target="_blank"
              >
                <AiOutlineFacebook size={38} color="#9D1C3B" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip735272548">
                תאהבו אותנו
              </UncontrolledTooltip>
              <Button
                className="btn-neutral btn-icon btn-round"
                color="linkedin"
                href="/"
                id="tooltip647117716"
                size="lg"
                target="_blank"
              >
                <AiOutlineInstagram size={38} color="#9D1C3B" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip647117716">
                עקבו אחרינו
              </UncontrolledTooltip>
            </Col>
          </Row> */}
        </Container>
      </div>
    </>
  );
}

export default Download;
