import set from 'lodash/set';
import merge from 'lodash/merge';
import { createSlice } from '@reduxjs/toolkit';

export const invitationsPageInitialState = {
  type: '', // [ wedding, bar-mitzva, bat-mitzva, ... ]
  step: 1, // [ 1, 2, 3 ]
  video: null,
  audio: null,
  specificSong: '',
  customer: { name: '', email: '', phone: '' },
  details: {
    place: { type: '', name: '', address: '' },
    dates: { event: '', welcome: '', huppa: '', party: '' },
    owners: { groom: '', bride: '', groom_parents: '', bride_parents: '' },
  },
  paymentStatus: 0, // { 0: not_paid, fail_pay: 1, success_pay: 2  }
  emailStatus: 0, // { 0: not_sent, fail_send: 1, success_send: 2  }
};

export const invitationsSlice = createSlice({
  name: 'invitations-page',
  initialState: invitationsPageInitialState,
  reducers: {
    setInvitationDetails: (state, action) => {
      const { path, value } = action.payload;
      set(state, `details.${path}`, value);
    },
    setInvitationCustomer: (state, action) => {
      const { path, value } = action.payload;
      set(state, `customer.${path}`, value);
    },
    setInvitationType: (state, action) => {
      state.type = action.payload;
    },
    setInvitationStep: (state, action) => {
      state.step = action.payload;
    },
    setInvitationVideo: (state, action) => {
      state.video = action.payload;
    },
    setInvitationAudio: (state, action) => {
      state.audio = action.payload;
    },
    setInvitationSpecificSong: (state, action) => {
      state.specificSong = action.payload;
    },
    setInvitationPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload;
    },
    setInvitationEmailStatus: (state, action) => {
      state.emailStatus = action.payload;
    },
    clearInvitation: state => {
      merge(state, { ...invitationsPageInitialState });
    },
  },
});

export const { 
  clearInvitation,
  setInvitationType, 
  setInvitationStep,
  setInvitationAudio, 
  setInvitationVideo, 
  setInvitationDetails,
  setInvitationCustomer,
  setInvitationEmailStatus,
  setInvitationSpecificSong,
  setInvitationPaymentStatus,
} = invitationsSlice.actions;

export default invitationsSlice.reducer;