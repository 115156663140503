import isEmpty from 'lodash/isEmpty';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

export const validations = {
    isName: n => { return !isEmpty(n) && n.length > 1; },
    isDate: d => { return /^[0-3]?[0-9]\.[0-1]?[0-9]\.202[1-9]$/.test(d); },
    isTime: t => { return /^([0-2]?[0-9]:[0-5][0-9])$/.test(t); },
    isPhone: p => { return isMobilePhone(p, 'he-IL') && /^((\+972)|0)\d{9}$/.test(p); },
};

export const validateDetails = (customer, details) => {
    if (!validations.isName(customer.name)) { return false; }
    if (!validations.isPhone(customer.phone)) { return false; }
    if (!isEmail(customer.email)) { return false; }
    if (!validations.isName(details.place.type)) { return false; }
    if (!validations.isName(details.place.name)) { return false; }
    if (!validations.isName(details.place.address)) { return false; }
    if (!validations.isDate(details.dates.event)) { return false; }
    if (!validations.isTime(details.dates.welcome)) { return false; }
    if (!validations.isTime(details.dates.huppa)) { return false; }
    if (!validations.isName(details.owners.groom)) { return false; }
    if (!validations.isName(details.owners.bride)) { return false; }
    return true;
};