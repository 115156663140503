import axios from 'axios';
import trim from 'lodash/trim';
import last from 'lodash/last';
import first from 'lodash/first';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useState, useEffect } from 'react';
import { Container, Button, Input, FormGroup, UncontrolledCollapse } from 'reactstrap';

import { validateDetails } from 'utils/validations';
import { setCouponCode, clearCouponCode } from 'store/features/checkout-page';
import { setInvitationPaymentStatus, setInvitationEmailStatus, clearInvitation } from 'store/features/invitations-page';

import fullLogo from 'assets/img/laerua-full-logo.svg';

import Layout from '../layouts';

const COUPONS = {
    'אלכס-איוונוב-לאירוע-21': { sale: 15 },
    'בן-מורני-לאירוע-21': { sale: 15 },
};

const styles = {
    text: { textShadow: 'none', color: '#9a233d', fontSize: 30 },
    homeLink: { fontSize: 22, textDecoration: 'underline' },
    couponContainer: { display: 'flex', justifyContent: 'space-between' },
    couponEnter: { fontSize: 22, textDecoration: 'underline', display: 'block' },
    couponInput: { width: '67%' },
    couponButton: { width: '33%', fontSize: 20, height: 46 },
};

const createOrder = (customer, price = 179) => ({
    payer: {
        name: { given_name: first(customer.name.split(/\s+/)), surname: last(customer.name.split(/\s+/)) || '' },
        email_address: customer.email,
        phone: { phone_type: 'MOBILE', phone_number: { national_number: customer.phone } },
        address: { country_code: 'IL' },
    },
    purchase_units: [{ amount: { value: price } }],
    application_context: { shipping_preference: 'NO_SHIPPING' },
});

const PostCheckoutMessage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { emailStatus } = useSelector(state => state.invitationsPage);

    useEffect(function onEmailSent() {
        if (emailStatus === 2) {
            window.ga4 && window.ga4.event('checkout_email', 'email sent', 'checkout', false);
            const timeout = window.setTimeout(() => {
                dispatch(clearInvitation());
                dispatch(clearCouponCode());
                history.push('/?purchase-completed=1');
            }, 5000);
            return () => {
                window.clearTimeout(timeout);
            };
        } else if (emailStatus === 1) {
            window.ga4 && window.ga4.event('checkout_email', 'email failed', 'checkout', false);
        }
    }, [emailStatus]);

    return (
        <div>
            <h3 style={styles.text}>
                מזל טוב! התשלום התקבל, מתחילים להכין עבורכם את הסרטון המרגש 🎉 
            </h3>
            {emailStatus === 0 && (
                <h4 style={styles.text}>ההזמנה בהקמה, אנא המתינו עוד מספר שניות...</h4>
            )}
            {emailStatus === 1 && (
                <div>
                    <h4 style={styles.text}>התשלום עבר אך הקמת ההזמנה נכשלה. אנא צרו איתנו קשר ישיר כדי לתקן את הבעיה.</h4>
                    <Link to="/contact" title="Contact"><Button>יצירת קשר</Button></Link>
                </div>
            )}
            {emailStatus === 2 && (
                <div className="mt-2">
                    <h4 style={styles.text}>ההזמנה הוקמה בהצלחה!</h4>
                        <div className="mt-2">
                        <Link 
                            title="Home Page"
                            style={styles.homeLink}
                            to="/?purchase-completed=1"
                            onClick={() => {
                                dispatch(clearCouponCode());
                                dispatch(clearInvitation());
                            }} 
                        >
                            חזרה לדף הראשי
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};  

const CheckoutPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const paypalButtonsRef = useRef();
    const [price, setPrice] = useState(179);
    const [coupon, setCoupon] = useState('');
    const [couponMessage, setCouponMesage] = useState('');
    const { invitationsPage, checkoutPage } = useSelector(state => state);

    const { couponCode } = checkoutPage;
    const { 
        type, 
        step, 
        video,
        audio, 
        specificSong, 
        details, 
        customer, 
        paymentStatus, 
        emailStatus,
    } = invitationsPage;

    const clearPaypalButtonsContainer = () => {
        if (paypalButtonsRef && paypalButtonsRef.current) {
            paypalButtonsRef.current.innerHTML = '';
        }
    };

    const applyCoupon = e => {
        e.preventDefault();
        if (!COUPONS[coupon]) {
            return setCouponMesage('לא קיים קופון כזה במערכת');
        }
        dispatch(setCouponCode(coupon));
    };
    
    const sendMail = async () => {
        const body = { 
            type,
            step,
            video,
            audio,
            specificSong,
            details,
            customer,
            couponCode,
        };
        try {
          const { data } = await axios.post('/.netlify/functions/email', body);
          if (data === 'success') { return true; }
          throw new Error('Sending Failed!');
        } catch(e) {
          return false;
        }
    };

    useEffect(() => {
        if (couponCode && COUPONS[couponCode]) {
            const { sale = 0 } = COUPONS[couponCode];
            setPrice(Math.ceil((100 - sale) * 179 / 100));
        }
    }, [couponCode]);

    useEffect(function loadPaypal() {
        if (!type || !video || (!audio && !specificSong) || !validateDetails(customer, details)) {
            return history.push(`/invitations/${type || 'wedding'}`);
        }
        if (paymentStatus === 0) {
            window.paypal.Buttons({
                style: { color: 'blue', shape: 'pill', label: 'pay' },
                createOrder: (_, actions) => {
                    return actions.order.create(createOrder(customer, price));
                },
                onApprove: (_, actions) => {
                    return actions.order.capture().then(async details => {
                        clearPaypalButtonsContainer();
                        dispatch(setInvitationPaymentStatus(details?.status === 'COMPLETED' ? 2 : 1));
                        window.ga4 && window.ga4.event('checkout_approved', 'approved', 'checkout', true);
                    });
                },
                onError: () => {
                    clearPaypalButtonsContainer();
                    dispatch(setInvitationPaymentStatus(1));
                    window.ga4 && window.ga4.event('checkout_error', 'error', 'checkout', true);
                },
                onCancel: () => {
                    window.ga4 && window.ga4.event('checkout_canceled', 'canceled', 'checkout', false);
                },
            }).render('#paypal-button-container');
        }
    }, []);

    useEffect(function onPaid() {
        if (paymentStatus === 2 && emailStatus !== 2) {
            (async () => {
                const emailSent = await sendMail();
                dispatch(setInvitationEmailStatus(emailSent ? 2 : 1));
            })();
        }
    }, [paymentStatus]);

    return (
        <Layout>
            <div className="main">
                <div 
                    id="checkout" className="section invitations-section page-header text-center" 
                    style={{ backgroundImage: `url(${require('assets/img/bg-red.jpeg').default})` }}
                >
                    <Container>
                        <div className='content-center brand'>
                            <img alt='Laerua Logo' className='n-logo' src={fullLogo} />
                            <h1 className='h1-seo'>תשלום מאובטח 🔒</h1>
                            <h2 style={{maxWidth: 700}}>
                                ניתן לשלם באמצעות כרטיס אשראי או חשבון הפייפל שלכם. במידה וברשותכם קופון הנחה, אנא הזינו אותו ולחצו ״הפעלה״.                              
                            </h2>
                            <h3>
                                *לאחר התשלום, תישלח אליכם חשבונית למייל. 24 שעות או פחות לאחר מכן - הסרטון אצלכם בוואטספ.
                            </h3>
                        </div>
                        <div id="paypal-button-container" ref={paypalButtonsRef}>
                            {paymentStatus === 0 && (
                                <>
                                    <h3 className="mb-4" style={styles.text}>
                                        סכום מלא לתשלום - <strong>{price} ש״ח</strong>
                                    </h3>
                                    {!couponCode && (
                                        <>
                                            <a 
                                                id="coupon-code" 
                                                className="mb-4"
                                                href="#enter-coupon" 
                                                style={styles.couponEnter}
                                                onClick={e => e.preventDefault()}
                                            >
                                                לחצו להזנת קוד קופון
                                            </a>
                                            <UncontrolledCollapse role="tabpanel" toggler="#coupon-code">
                                                <Container style={styles.couponContainer}>
                                                    <FormGroup className="input-lg" style={styles.couponInput}>
                                                        <Input 
                                                            type="text"
                                                            value={coupon}
                                                            placeholder="קוד הקופון"
                                                            onChange={e => {
                                                                e.stopPropagation();
                                                                setCoupon(e.target.value);
                                                                setCouponMesage('');
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    <Button
                                                        href='/apply-coupon'
                                                        onClick={applyCoupon}
                                                        className='btn-round mt-0'
                                                        style={styles.couponButton}
                                                        block size='md' color='info'
                                                    >
                                                        הפעלה
                                                    </Button>
                                                </Container>
                                                {couponMessage && (
                                                    <h5 style={{color: '#9D1C3B'}}>{couponMessage}</h5>
                                                )}
                                            </UncontrolledCollapse>
                                            <hr style={{margin: '30px 0'}} />
                                        </>
                                    )}
                                </>
                            )}
                            {paymentStatus === 1 && (
                                <div>
                                    <h4 style={styles.text}>אנחנו מצטערים אך התשלום נכשל. ניתן ליצור איתנו קשר או לנסות בשנית.</h4>
                                    <div className="text-center mt-2">
                                        <Link 
                                            to="/checkout" 
                                            onClick={e => { 
                                                e.preventDefault(); 
                                                dispatch(setInvitationPaymentStatus(0)); 
                                                window.location.reload();
                                            }}
                                        >
                                            <Button
                                                href="/checkout"
                                                style={{fontSize: 18}}
                                                className="btn-round ml-2"
                                                size="lg" color="info" role="button"
                                            >
                                                לנסות מחדש
                                            </Button>
                                        </Link>
                                        <Link to="/contact">
                                            <Button
                                                style={{fontSize: 18}}
                                                className="btn-round ml-2"
                                                role="button" href="/contact"
                                                outline size="lg" color="info"
                                            >
                                                יצירת קשר
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            )}
                            {paymentStatus === 2 && (
                                <PostCheckoutMessage />
                            )}
                        </div>
                    </Container>
                </div>
            </div>
        </Layout>
    );
};

export default CheckoutPage;
