import qs from 'qs';
import React from 'react';
import { FaRegFileVideo } from 'react-icons/fa';
import { AiOutlineFileText } from 'react-icons/ai';
import { HiOutlineMusicNote } from 'react-icons/hi';
import { useLocation, useHistory } from 'react-router-dom';
import { Container, Row, Col, Button, Alert } from 'reactstrap';

import IndexHeader from 'components/Headers/IndexHeader';

import Layout from './layouts';
import SocialShare from './index-sections/SocialShare';

import Images from './index-sections/Images';
import BasicElements from './index-sections/BasicElements';
import Navbars from './index-sections/Navbars';
import Tabs from './index-sections/Tabs';
import Pagination from './index-sections/Pagination';
import Notifications from './index-sections/Notifications';
import Typography from './index-sections/Typography';
import Javascript from './index-sections/Javascript';
import Carousel from './index-sections/Carousel';
import NucleoIcons from './index-sections/NucleoIcons';
import CompleteExamples from './index-sections/CompleteExamples';
import SignUp from './index-sections/SignUp';
import Invitations from './index-sections/Invitations';
import Download from './index-sections/Download';

const styles = {
  purchaseNotification: {
    zIndex: 2,
    padding: 0,
    bottom: -16,
    width: '100%',
    position: 'fixed',
  },
  purchaseNotificationContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  purchaseNotificationText: {
    fontSize: 26,
  },
};

const Index = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <Layout>
      <Alert 
        color="info" 
        style={styles.purchaseNotification}
        isOpen={queryParams['purchase-completed'] === '1'}
      >
        <div className="container text-center" style={styles.purchaseNotificationContainer}>
          <h4 style={styles.purchaseNotificationText}>מזל טוב 🎉 הסרטון שלכם בהכנה ובקרוב ישלח אליכם.</h4>
          <button
            type="button"
            aria-label="Close"
            className="close mr-3 mt-3 mb-1s"
            onClick={() => history.push('/')}
          >
            <span aria-hidden="true">
              <i className="now-ui-icons ui-1_simple-remove" />
            </span>
          </button>
        </div>
      </Alert>

      <SocialShare />

      <IndexHeader />
      
      <div className="main">
        <div 
          id="how-it-works" className="section invitation-section text-center" 
          style={{ backgroundImage: `url(${require('assets/img/bg-texture.jpeg').default})` }}
        >
          <Container>
            <h2 className="title">איך זה עובד?</h2>
              <Row>
                <Col md="4">
                  <div className="invitation-step">
                    <Button
                      onClick={e => e.preventDefault()}
                      color="info" href="#step-1" className="btn-icon btn-round btn-lg"
                    >
                      <FaRegFileVideo size={28} />
                    </Button>
                    <h4 className="category text-info">עיצוב</h4>
                    <h3>☝️ בוחרים תבנית עיצוב לטעמכם</h3>
                    <h4 className="description">בוחרים את תבנית העיצוב המתאימה לאירוע שלכם וקולעת לטעמכם האישי.</h4>
                  </div>
                </Col>
                <Col md="4">
                  <div className="invitation-step">
                    <Button
                      onClick={e => e.preventDefault()}
                      color="info" href="#step-2" className="btn-icon btn-round btn-lg"
                    >
                      <HiOutlineMusicNote size={29} />
                    </Button>
                    <h4 className="category text-info">מוזיקה</h4>
                    <h3>✌️ בוחרים שיר מהרשימה</h3>
                    <h4 className="description">בוחרים שיר מתוך מבחר גדול ועדכני של שירים בשיתוף פעולה עם אקו״ם.</h4>
                  </div>
                </Col>
                <Col md="4">
                  <div className="invitation-step">
                    <Button
                      onClick={e => e.preventDefault()}
                      color="info" href="#step-3" className="btn-icon btn-round btn-lg"
                    >
                      <AiOutlineFileText size={29} />
                    </Button>
                    <h4 className="category text-info">פרטים</h4>
                    <h3>🤟 ממלאים את פרטי האירוע</h3>
                    <h4 className="description">מידע כמו שמות, מקום האירוע, תאריך וכ׳. 24 שעות (לכל היותר) והסרטון אצלכם בוואטספ!</h4>
                  </div>
                </Col>
              </Row>
          </Container>
        </div>

        <Download />

        {/* <Images /> */}
        {/* <BasicElements />
        <Navbars />
        <Tabs />
        <Pagination />
        <Notifications />
        <Typography />
        <Javascript />
        <Carousel />
        <NucleoIcons />
        <CompleteExamples />
        <SignUp />
        <Download /> */}
      </div>
    </Layout>
  );
};

export default Index;
