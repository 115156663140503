import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import isEmail from 'validator/lib/isEmail';
import React, { useState, useEffect } from 'react';
import { Button, Card, Row, CardBody, CardFooter, Form, FormGroup, Input, Container, Col } from 'reactstrap';

import { validations } from 'utils/validations';

import fullLogo from 'assets/img/laerua-full-logo.svg';

import Layout from '../layouts';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [emailStatus, setEmailStatus] = useState(0); // 0 - not send, 1 - failed send, 2 - sent succesfully, 3 - validation error

    const sendContactMail = async e => {
        e.preventDefault();
        if (!validations.isName(name) || !isEmail(email) || !validations.isPhone(phone) || !message || message.length < 5) {
            return setEmailStatus(3);
        }
        const body = { name, email, phone, message };
        try {
          const { data } = await axios.post('/.netlify/functions/contact', body);
          if (data === 'success') { return setEmailStatus(2); }
          throw new Error('Sending Failed!');
        } catch(e) {
          setEmailStatus(1);
        }
    };

    useEffect(function onEmailStatusChanged() {
        if (emailStatus === 1) {
            window.ga4 && window.ga4.event('contact_mail', 'failed to send', 'contact', false);
        } else if (emailStatus === 2) {
            window.ga4 && window.ga4.event('contact_mail', 'sent successfuly', 'contact', false);
        } 
    }, [emailStatus]);

    useEffect(() => {
        document.body.classList.add('login-page');
        document.body.classList.add('sidebar-collapse');
        document.documentElement.classList.remove('nav-open');
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove('login-page');
            document.body.classList.remove('sidebar-collapse');
        };
    }, []);

  return (
    <Layout>
      <div className='page-header clear-filter' filter-color='blue'>
        <div className='page-header-image' style={{ backgroundImage: `url(${require('assets/img/bg-red.jpeg').default})` }} />
        <Container>
            <div className='content-center brand'>
                <img alt='Laerua Logo' className='n-logo' src={fullLogo} />
                <h1 className='h1-seo'>יצירת קשר</h1>
                <h2 className="h2-seo">ניתן לשלוח לנו מייל, לכתוב לנו בוואטספ או פשוט להרים אלינו טלפון. נשמח לעזור!</h2>
            </div>
            <Row>
                <Col className='ml-auto mr-auto' lg='6' md='8' sm='12'>
                    <Card className='card-login card-plain mt-4'>
                        <Form action='/' className='form' method='post'>
                            <CardBody>
                                {emailStatus !== 2 && (
                                    <>
                                        <FormGroup className={`no-border input-lg ${isEmpty(name) ? '' : validations.isName(name) ? 'has-success' : 'has-danger'}`}>
                                            <Input 
                                                type='text' 
                                                value={name} 
                                                placeholder='שם מלא' 
                                                onChange={e => setName(e.target.value)}
                                                className={isEmpty(name) ? '' : validations.isName(name) ? 'form-control-success' : 'form-control-danger'}
                                            />
                                        </FormGroup>
                                        <FormGroup className={`no-border input-lg ${isEmpty(email) ? '' : isEmail(email) ? 'has-success' : 'has-danger'}`}>
                                            <Input 
                                                type='text' 
                                                value={email} 
                                                placeholder='כתובת המייל' 
                                                onChange={e => setEmail(e.target.value)}
                                                className={isEmpty(email) ? '' : isEmail(email) ? 'form-control-success' : 'form-control-danger'}
                                            />
                                        </FormGroup>
                                        <FormGroup className={`no-border input-lg ${isEmpty(phone) ? '' : validations.isPhone(phone) ? 'has-success' : 'has-danger'}`}>
                                            <Input 
                                                type='number' 
                                                value={phone} 
                                                placeholder='מספר הטלפון' 
                                                onChange={e => setPhone(e.target.value)}
                                                className={isEmpty(phone) ? '' : validations.isPhone(phone) ? 'form-control-success' : 'form-control-danger'}
                                            />
                                        </FormGroup>
                                        <FormGroup className={`no-border input-lg ${isEmpty(message) ? '' : message.length > 5 ? 'has-success' : 'has-danger'}`}>
                                            <Input 
                                                type='textarea' 
                                                value={message} 
                                                placeholder='תוכן ההודעה' 
                                                onChange={e => setMessage(e.target.value)}
                                                className={isEmpty(message) ? '' : message.length > 5 ? 'form-control-success' : 'form-control-danger'}
                                            />
                                        </FormGroup>
                                    </>
                                )}
                                {emailStatus === 1 && (
                                    <p className="h4">שליחת המייל נכשלה. אנא נסו שוב או לחלופין צרו איתנו קשר בוואטספ או בטלפון.</p>
                                )}
                                {emailStatus === 2 && (
                                    <p className="h1">תודה שיצרתם עמנו קשר. נחזור אליכם בהקדם!</p>
                                )}
                                {emailStatus === 3 && (
                                    <p className="h4">יש למלא את כל הפרטים על מנת לשלוח מייל.</p>
                                )}
                            </CardBody>
                            <CardFooter className='text-center'>
                                {emailStatus !== 2 && (
                                    <Button
                                        block
                                        size='lg'
                                        color='info'
                                        style={{fontSize: 20}}
                                        href='/send-contact-email'
                                        className='btn-round mt-0'
                                        onClick={sendContactMail}
                                    >
                                        שליחת הודעה
                                    </Button>
                                )}
                                <div className='pull-left mt-2'>
                                    <a className='link' href='tel:+972503546454' title='לחצו להתקשרות'>
                                        להתקשרות
                                    </a>
                                </div>
                                <div className='pull-right mt-2'>
                                    <a
                                        className='link'
                                        target="_blank"
                                        href='https://api.whatsapp.com/send?phone=972503546454&text=שלום צוות לאירוע!'
                                    >
                                        לפנייה בוואטספ
                                    </a>
                                </div>
                            </CardFooter>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>
    </Layout>
  );
};

export default Contact;
