import Select from 'react-select';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import startCase from 'lodash/startCase';
import isEmail from 'validator/lib/isEmail';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useState, useEffect } from 'react';
import { BiPlayCircle, BiPauseCircle } from 'react-icons/bi';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Button, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Form, FormGroup, Input, Label, FormText } from 'reactstrap';

import iphone from 'assets/img/iphone.png';

import { 
  setInvitationStep, 
  setInvitationAudio, 
  setInvitationVideo, 
  setInvitationDetails,
  setInvitationCustomer,
  setInvitationSpecificSong, 
} from 'store/features/invitations-page';

import { validations, validateDetails } from 'utils/validations';

const gifConfig = require('assets/gif/config.json');
const musicConfig = require('assets/music/config.json');

const placeOptions = [
  { value: 'אולם אירועים', label: 'אולם אירועים' },
  { value: 'גן אירועים', label: 'גן אירועים' },
  { value: 'חצר בית', label: 'חצר בית' },
  { value: 'בטבע', label: 'בטבע' },
];

const styles = {
  fixedBottom: { width: '100%', fontSize: 20, boxShadow: '0 -1px 3px #9b203c' },
  step2: { flexDirection: 'column', alignItems: 'center' },
  step2PlayButton: { width: 200, fontSize: 22, fontFamily: 'Bellefair, sans-serif' },
  step2Video: { position: 'absolute', top: 45, left: 0, right: 0, margin: '0 auto' },
};

const dot = () => ({
  ':before': { content: '"🎵"', marginRight: 0, marginLeft: 10 },
});

const colourStyles = {
  singleValue: styles => ({ ...styles, ...dot() }),
  input: styles => ({ ...styles, marginRight: 40 }),
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  placeholder: styles => ({ ...styles, ...dot(), marginRight: 10 }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? '#f4f4f4' : null,
    color: isDisabled ? '#ccc' : isSelected ? '#9d1c3b' : '#000',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    ':active': { ...styles[':active'], backgroundColor: 'white' },
  }),
};

const Invitations = () => {
  const audioRef = useRef(null);
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const [chosenSong, setChosenSong] = useState('');
  const [videoPlaying, setVideoPlaying] = useState(false);
  const { type, step, video, audio, specificSong, details, customer } = useSelector(state => state.invitationsPage);

  const onVideoClick = video => e => {
    e.preventDefault();
    dispatch(setInvitationVideo(video));
  };

  useEffect(function onStepChanged() {
    const navEl = document.querySelector('nav');
    const scrollEl = document.querySelector('.card');
    if (scrollEl) {
      window.scrollTo(0, scrollEl?.getBoundingClientRect().top + window.pageYOffset - navEl?.clientHeight - 10);
    }
    window.ga4 && window.ga4.event('step_changed', step, 'invitations', false);
  }, [step]);

  useEffect(function onStepChanged() {
    if (videoPlaying) {
      videoRef?.current?.play();
      if (audio) {
        audioRef?.current?.play();
      }
    } else {
      videoRef?.current?.pause();
      if (audio) {
        audioRef?.current?.pause();
      }
    }
    // if (videoPlaying && videoRef && videoRef.current) {
    //   const onEnd = () => {
    //     videoRef.current.currentTime = 0;
    //     audioRef && audioRef.current && (audioRef.current.currentTime = 0);
    //     setVideoPlaying(false);
    //   };
    //   videoRef.current.addEventListener('ended', onEnd);
    //   return () => {
    //     videoRef.current.removeEventListener('ended', onEnd);
    //   };
    // }
  }, [videoPlaying]);

  useEffect(function onSongChosen() {
    if (chosenSong) {
      if (chosenSong?.value !== 'specific') {
        dispatch(setInvitationAudio(chosenSong.value));
        dispatch(setInvitationSpecificSong(''));
      } else {
        dispatch(setInvitationAudio(null));
      }
    }
  }, [chosenSong]);

  useEffect(function onVideoChosen() {
    if (video) {
      window.ga4 && window.ga4.event('video_chosen', video, 'invitations', false);
    }
  }, [video]);

  useEffect(function onAudioChanged() {
    if (audio || specificSong) {
      window.ga4 && window.ga4.event('audio_chosen', audio || specificSong, 'invitations', false);
    }
  }, [audio, specificSong]);

  return (
    <div className='section section-invitations'>
      <Row>
        <Col className='ml-auto mr-auto' sm='12' md='12' lg='12' xl='12'>
          <Card>
            <CardHeader>
              <Nav
                tabs role='tablist' data-background-color='blue'
                className='nav-tabs-neutral justify-content-center'
              >
                <NavItem>
                  <NavLink
                    href='#step-1'
                    className={step === 1 ? 'active' : ''}
                    onClick={e => {
                      e.preventDefault();
                      setVideoPlaying(false);
                      dispatch(setInvitationStep(1));
                    }}
                  >
                    1. תבנית עיצוב
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href='#step-2'
                    disabled={!video}
                    className={step === 2 ? 'active' : ''}
                    onClick={e => {
                      e.preventDefault();
                      dispatch(setInvitationStep(2));
                    }}
                  >
                    2. בחירת שיר
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href='#step-3'
                    disabled={!audio && !specificSong}
                    className={step === 3 ? 'active' : ''}
                    onClick={e => {
                      e.preventDefault();
                      setVideoPlaying(false);
                      dispatch(setInvitationStep(3));
                    }}
                  >
                    3. פרטי האירוע
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href='#step-4'
                    className={step === 4 ? 'active' : ''}
                    disabled={!validateDetails(customer, details)}
                    onClick={e => {
                      e.preventDefault();
                      dispatch(setInvitationStep(4));
                    }}
                  >
                    4. סיכום ההזמנה
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>

            <CardBody>
              <TabContent className='text-center' activeTab={`step${step}`}>
                <TabPane tabId='step1'>
                  <Row>
                  {values(gifConfig[type]).map(({ id, name }) => (
                    <div key={name} className='col'>
                      <a href={`/invitations/${type}/${name}`} target='_blank' onClick={onVideoClick(id)}>
                        <img
                          alt={startCase(name)} title={startCase(name)}
                          src={require(`assets/gif/${type}/${id}.gif`).default}
                          className={`img-raised ${video === id ? 'selected' : ''}`}
                        />
                      </a>
                    </div>
                  ))}
                  <div className='fixed-bottom bg-white' style={styles.fixedBottom}>
                    <Button 
                      onClick={() => !!video && dispatch(setInvitationStep(2))}
                      disabled={!video} className='btn btn-info btn-md btn-round'
                    >
                      הבא
                      <MdKeyboardArrowLeft size={22} />
                    </Button>
                  </div>
                </Row>
                </TabPane>
                <TabPane tabId='step2'>
                  <Row style={styles.step2}>
                    <Select 
                      value={chosenSong}
                      styles={colourStyles}
                      className='react-select mb-2'
                      placeholder='בחרו שיר לסרטון'
                      noOptionsMessage={() => 'לא נמצאו שירים התואמים לחיפוש'}
                      options={!type ? [] : [{ value: 'specific', label: '💫 בחירת שיר ספציפי 💫' }, ...musicConfig[type]]} 
                      onChange={song => {
                        setChosenSong(song);
                        setVideoPlaying(false);
                        videoRef && videoRef.current && (videoRef.current.currentTime = 0);
                        audioRef && audioRef.current && (audioRef.current.currentTime = 0);
                      }}
                    />
                    {chosenSong.value && chosenSong.value === 'specific' && (
                      <div className="text-center mt-2 mb-2">
                        <h3 className="mb-2">רשמו את שם השיר אותו תרצו:</h3>
                        <Form>
                          <FormGroup className="mb-2">
                            <Input 
                              autoFocus
                              type="text" 
                              style={{width: 320}}
                              value={specificSong}
                              className="form-control m-auto" 
                              placeholder="לדוגמא: צביקה פיק - מרי לו 01:34"  
                              onChange={e => {
                                dispatch(setInvitationSpecificSong(e.target.value));
                              }}
                            />
                          </FormGroup>
                        </Form>
                        <h5 style={{maxWidth: 410}}>
                          לא ניתן להשמיע שיר ספציפי מכיוון שהוא לא נמצא במאגר השירים שלנו.
                          במידה ואתם רוצים שהשיר יתחיל בזמן ספציפי יש לרשום את שניות ההתחלה.
                          לדוגמא: <strong>צביקה פיק - מרי לו 01:34</strong>.
                          במידה ולא ציינתם זמן, נתאים את השיר שבחרתם בצורה הטובה ביותר לההזמנה שלכם.
                        </h5>
                      </div>
                    )}
                    {chosenSong.value && chosenSong.value !== 'specific' && (
                      <Button 
                        style={styles.step2PlayButton}
                        className='btn btn-md btn-info btn-round mt-4' 
                        onClick={() => setVideoPlaying(!videoPlaying)}
                      >
                        <span className='ml-2'>{videoPlaying ? 'עצרו' : 'נגנו'} את הסרטון</span>
                        {!videoPlaying && <BiPlayCircle size={24} />}
                        {videoPlaying && <BiPauseCircle size={24} />}
                      </Button>
                    )}
                    {video && (
                      <div style={{position: 'relative'}}>
                        <img src={iphone} alt='iphone' title='iphone' width='225' className="audio-iphone" />
                        <video 
                          width='200' 
                          perload='auto' 
                          ref={videoRef}
                          style={styles.step2Video} 
                          poster={require(`assets/gif/${type}/${video}.gif`).default}
                          src={require(`assets/video/${type}/${video}.mp4`).default} 
                        >
                          <source 
                            type='video/mp4'
                            src={require(`assets/video/${type}/${video}.mp4`).default} 
                          />
                          <source 
                            type='video/ogg'
                            src={require(`assets/video/${type}/${video}.mp4`).default} 
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )}
                    {audio && (
                      <audio ref={audioRef} src={require(`assets/music/${type}/${audio}`).default} preload="auto">
                        Your browser does not support the <code>audio</code> element.
                      </audio>
                    )}
                  </Row>
                  <div className='fixed-bottom bg-white' style={styles.fixedBottom}>
                    <Button 
                        outline color='info' className='btn btn-md btn-round'
                        onClick={() => {
                          setVideoPlaying(false);
                          dispatch(setInvitationStep(1));
                        }}
                      >
                        <MdKeyboardArrowRight />
                        הקודם
                      </Button>
                      <Button 
                        onClick={() => {
                          if (!!audio) {
                            setVideoPlaying(false);
                            dispatch(setInvitationStep(3));
                          }
                        }}
                        disabled={!audio} className='btn btn-info btn-md btn-round'
                      >
                        הבא
                        <MdKeyboardArrowLeft size={22} />
                    </Button>
                  </div>
                </TabPane>
                <TabPane tabId='step3'>
                  <Form>
                  <Row>
                      <h2 className="mt-4 mb-2" style={{fontSize: 28, fontWeight: 700, letterSpacing: '1.2px'}}>☝️ הפרטים שלכם</h2>
                    </Row>  
                    <Row>
                        <Col sm='6' md='6' lg='4' xl='4'>
                          <FormGroup className={isEmpty(customer.name) ? '' : validations.isName(customer.name) ? 'has-success' : 'has-danger'}>
                            <Label size="lg">שמכם המלא *</Label>
                            <Input 
                              required type="text" 
                              value={customer.name} 
                              placeholder="השם שלך :)"
                              className={isEmpty(customer.name) ? '' : validations.isName(customer.name) ? 'form-control-success' : 'form-control-danger'}
                              onChange={e => {
                                dispatch(setInvitationCustomer({ path: 'name', value: e.target.value }))
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm='6' md='6' lg='4' xl='4'>
                          <FormGroup className={isEmpty(customer.phone) ? '' : validations.isPhone(customer.phone) ? 'has-success' : 'has-danger'}>
                            <Label size="lg">מספר נייד לשליחת סרטון *</Label>
                            <Input 
                              required type="number" 
                              value={customer.phone} 
                              placeholder="0503546454"
                              className={isEmpty(customer.phone) ? '' : validations.isPhone(customer.phone) ? 'form-control-success' : 'form-control-danger'}
                              onChange={e => {
                                dispatch(setInvitationCustomer({ path: 'phone', value: e.target.value }))
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm='6' md='6' lg='4' xl='4'>
                          <FormGroup className={isEmpty(customer.email) ? '' : isEmail(customer.email) ? 'has-success' : 'has-danger'}>
                            <Label size="lg">כתובת המייל שלכם *</Label>
                            <Input 
                              required type="email" 
                              value={customer.email} 
                              placeholder="example@gmail.com" 
                              className={isEmpty(customer.email) ? '' : isEmail(customer.email) ? 'form-control-success' : 'form-control-danger'}
                              onChange={e => {
                                dispatch(setInvitationCustomer({ path: 'email', value: e.target.value }))
                              }}
                            />
                          </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                      <h2 className="mt-4 mb-2" style={{fontSize: 28, fontWeight: 700, letterSpacing: '1.2px'}}>✌️ מקום האירוע</h2>
                    </Row>  
                    <Row>
                        <Col sm='6' md='6' lg='4' xl='4'>
                          <FormGroup>
                            <Label size="lg">מקום האירוע *</Label>
                            <Select 
                              options={placeOptions} 
                              placeholder='בחרו את סוג המקום'
                              noOptionsMessage={() => 'לא קיימת התאמה'}
                              value={{ value: details.place.type, label: details.place.type }}
                              styles={{ ...colourStyles, placeholder: s => s, singleValue: s => s }}
                              onChange={({ value }) => {
                                dispatch(setInvitationDetails({ path: 'place.type', value }));
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm='6' md='6' lg='4' xl='4'>
                          <FormGroup className={isEmpty(details.place.name) ? '' : validations.isName(details.place.name) ? 'has-success' : 'has-danger'}>
                            <Label size="lg">שם האולם / מקום *</Label>
                            <Input 
                              required type="text" 
                              value={details.place.name} 
                              placeholder="לדוגמא - אולמי אמארה" 
                              className={isEmpty(details.place.name) ? '' : validations.isName(details.place.name) ? 'form-control-success' : 'form-control-danger'}
                              onChange={e => {
                                dispatch(setInvitationDetails({ path: 'place.name', value: e.target.value }))
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm='6' md='6' lg='4' xl='4'>
                          <FormGroup className={isEmpty(details.place.address) ? '' : validations.isName(details.place.address) ? 'has-success' : 'has-danger'}>
                            <Label size="lg">כתובת האולם / מקום *</Label>
                            <Input 
                              required type="text" 
                              value={details.place.address} 
                              className={isEmpty(details.place.address) ? '' : validations.isName(details.place.address) ? 'form-control-success' : 'form-control-danger'}
                              placeholder="לדוגמא - האלופים 1, נס ציונה" 
                              onChange={e => {
                                dispatch(setInvitationDetails({ path: 'place.address', value: e.target.value }))
                              }}
                            />
                          </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                      <h2 className="mt-4 mb-2" style={{fontSize: 28, fontWeight: 700, letterSpacing: '1.2px'}}>🤟 תאריך ושעות</h2>
                    </Row>
                    <Row>
                        <Col sm='6' md='6' lg='3' xl='3'>
                          <FormGroup className={isEmpty(details.dates.event) ? '' : validations.isDate(details.dates.event) ? 'has-success' : 'has-danger'}>
                            <Label size="lg">תאריך האירוע *</Label>
                            <Input 
                              required type="text" 
                              value={details.dates.event} 
                              placeholder="לדוגמא - 28.7.2021" 
                              className={isEmpty(details.place.event) ? '' : validations.isDate(details.dates.event) ? 'form-control-success' : 'form-control-danger'}
                              onChange={e => {
                                dispatch(setInvitationDetails({ path: 'dates.event', value: e.target.value }));
                              }}
                            />
                            <FormText color="default" style={{fontSize: 16}}>
                              תאריך עברי יוכנס אוטומטית להזמנה בהתאם לתאריך הלועזי.
                            </FormText>
                          </FormGroup>
                        </Col>
                        <Col sm='6' md='6' lg='3' xl='3'>
                          <FormGroup className={isEmpty(details.dates.welcome) ? '' : validations.isTime(details.dates.welcome) ? 'has-success' : 'has-danger'}>
                            <Label size="lg">שעת קבלת פנים *</Label>
                            <Input 
                              required type="text" 
                              placeholder="לדוגמא - 19:30"
                              value={details.dates.welcome}
                              className={isEmpty(details.place.welcome) ? '' : validations.isTime(details.dates.welcome) ? 'form-control-success' : 'form-control-danger'}
                              onChange={e => {
                                dispatch(setInvitationDetails({ path: 'dates.welcome', value: e.target.value }));
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm='6' md='6' lg='3' xl='3'>
                          <FormGroup className={isEmpty(details.dates.huppa) ? '' : validations.isTime(details.dates.huppa) ? 'has-success' : 'has-danger'}>
                            <Label size="lg">שעת החופה *</Label>
                            <Input 
                              required type="text" 
                              value={details.dates.huppa}
                              placeholder="לדוגמא - 20:30"
                              className={isEmpty(details.dates.huppa) ? '' : validations.isTime(details.dates.huppa) ? 'form-control-success' : 'form-control-danger'}
                              onChange={e => {
                                dispatch(setInvitationDetails({ path: 'dates.huppa', value: e.target.value }));
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm='6' md='6' lg='3' xl='3'>
                          <FormGroup className={isEmpty(details.dates.party) ? '' : validations.isTime(details.dates.party) ? 'has-success' : 'has-danger'}>
                            <Label size="lg">שעת תחילת המסיבה</Label>
                            <Input 
                              type="text" 
                              value={details.dates.party}
                              placeholder="לדוגמא - 21:30"
                              className={isEmpty(details.dates.party) ? '' : validations.isTime(details.dates.party) ? 'form-control-success' : 'form-control-danger'}
                              onChange={e => {
                                dispatch(setInvitationDetails({ path: 'dates.party', value: e.target.value }));
                              }}
                            />
                          </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                      <h2 className="mb-2" style={{fontSize: 28, fontWeight: 700, letterSpacing: '1.2px'}}>🖖 בעלי השמחה</h2>
                    </Row>                   
                    <Row className="mb-4">
                        <Col sm='6' md='6' lg='3' xl='3'>
                          <FormGroup className={isEmpty(details.owners.groom) ? '' : validations.isName(details.owners.groom) ? 'has-success' : 'has-danger'}>
                            <Label size="lg">שם החתן *</Label>
                            <Input 
                              required type="text" 
                              placeholder="לדוגמא - רונן" 
                              value={details.owners.groom}
                              className={isEmpty(details.owners.groom) ? '' : validations.isName(details.owners.groom) ? 'form-control-success' : 'form-control-danger'}
                              onChange={e => {
                                dispatch(setInvitationDetails({ path: 'owners.groom', value: e.target.value }));
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm='6' md='6' lg='3' xl='3'>
                          <FormGroup className={isEmpty(details.owners.bride) ? '' : validations.isName(details.owners.bride) ? 'has-success' : 'has-danger'}>
                            <Label size="lg">שם הכלה *</Label>
                            <Input 
                              required type="text" 
                              placeholder="לדוגמא - דנית" 
                              value={details.owners.bride}
                              className={isEmpty(details.owners.bride) ? '' : validations.isName(details.owners.bride) ? 'form-control-success' : 'form-control-danger'}
                              onChange={e => {
                                dispatch(setInvitationDetails({ path: 'owners.bride', value: e.target.value }));
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm='6' md='6' lg='3' xl='3'>
                          <FormGroup className={isEmpty(details.owners.groom_parents) ? '' : validations.isName(details.owners.groom_parents) ? 'has-success' : 'has-danger'}>
                            <Label size="lg">שמות הורי החתן</Label>
                            <Input 
                              required type="text" bsSize="lg"
                              value={details.owners.groom_parents}
                              placeholder="לדוגמא - שושנה וחיים אלימלך" 
                              className={isEmpty(details.owners.groom_parents) ? '' : validations.isName(details.owners.groom_parents) ? 'form-control-success' : 'form-control-danger'}
                              onChange={e => {
                                dispatch(setInvitationDetails({ path: 'owners.groom_parents', value: e.target.value }));
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm='6' md='6' lg='3' xl='3'>
                          <FormGroup className={isEmpty(details.owners.bride_parents) ? '' : validations.isName(details.owners.bride_parents) ? 'has-success' : 'has-danger'}>
                            <Label size="lg">שמות הורי הכלה</Label>
                            <Input 
                              required type="text" 
                              placeholder="שירה ומאור יעקב" 
                              value={details.owners.bride_parents}
                              className={isEmpty(details.owners.bride_parents) ? '' : validations.isName(details.owners.bride_parents) ? 'form-control-success' : 'form-control-danger'}
                              onChange={e => {
                                dispatch(setInvitationDetails({ path: 'owners.bride_parents', value: e.target.value }));
                              }}
                            />
                          </FormGroup>
                        </Col>
                    </Row>
                  </Form>
                  <div className='fixed-bottom bg-white' style={styles.fixedBottom}>
                    <Button 
                        onClick={() => dispatch(setInvitationStep(2))}
                        outline color='info' className='btn btn-md btn-round'
                      >
                        <MdKeyboardArrowRight />
                        הקודם
                      </Button>
                      <Button 
                        onClick={() => validateDetails(customer, details) && dispatch(setInvitationStep(4))}
                        disabled={!validateDetails(customer, details)} className='btn btn-info btn-md btn-round'
                      >
                        הבא
                        <MdKeyboardArrowLeft size={22} />
                    </Button>
                  </div>
                </TabPane>
                <TabPane tabId='step4'>
                  <h3>הסרטון הנבחר</h3>
                  {video && (
                    <div style={{position: 'relative'}}>
                      <img src={iphone} alt='iphone' title='iphone' width="195" />
                      <video 
                        style={styles.step2Video} 
                        src={require(`assets/video/${type}/${video}.mp4`).default} 
                        controls width="172" perload="auto" controlsList="nodownload nofullscreen noremoteplayback"
                      >
                        <source type='video/mp4' src={require(`assets/video/${type}/${video}.mp4`).default} />
                        <source type='video/ogg' src={require(`assets/video/${type}/${video}.mp4`).default} />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                  {(specificSong || audio) && (
                    <>
                      <h3>
                        השיר הנבחר: &nbsp;
                        <strong>{specificSong || musicConfig[type].find(s => s.value === audio).label}</strong> 
                      </h3>
                      {audio && (
                        <audio controls controlsList="nodownload" src={require(`assets/music/${type}/${audio}`).default} preload="auto">
                          Your browser does not support the <code>audio</code> element.
                        </audio>
                      )}
                      <div className='fixed-bottom bg-white' style={styles.fixedBottom}>
                        <Button 
                          onClick={() => dispatch(setInvitationStep(3))}
                          outline color='info' className='btn btn-md btn-round'
                        >
                          <MdKeyboardArrowRight />
                          הקודם
                        </Button>
                        <Link to="/checkout">
                          <Button className='btn btn-info btn-md btn-round'>
                            מעבר לתשלום
                            <MdKeyboardArrowLeft size={22} />
                          </Button>
                        </Link>
                      </div>
                    </>
                  )}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Invitations;
