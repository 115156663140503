import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Button, Container } from 'reactstrap';

// core components
import ProfilePageHeader from "components/Headers/ProfilePageHeader";
import Carousel from '../index-sections/Carousel';

import Layout from '../layouts';

function ProfilePage() {
  useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  
  return (
    <Layout>
        <ProfilePageHeader />
        <div className="section">
          <Container>
            <h3 className="title mb-2 mt-0">המחירון שלנו קליל ופשוט</h3>
            <h3 className="description">
              עבור הזמנה אחת, 179 ש״ח.
              <br />
              עבור 2 הזמנות, 319 ש״ח.
              <br />
              עבור 3 הזמנות, 419 ש״ח.
            </h3>
            <h3 className="text-center m-auto mb-4" style={{maxWidth: 420}}>לבקשות ומקרים מיוחדים, ניתן לפנות אלינו ישירות. במידה ותרצו מספר הזמנות, אנא צרו איתנו קשר בוואטספ לפני ביצוע ההזמנה באתר.</h3>
            <div className="text-center mt-2">
                <Link to="/invitations/wedding">
                    <Button
                        size="lg"
                        color="info"
                        role="button"
                        style={{fontSize: 20}}
                        className="btn-round ml-2"
                        href="/invitations/wedding"
                    >
                        עיצוב הזמנה
                    </Button>
                </Link>
                <Link to="/contact">
                    <Button
                        outline
                        size="lg"
                        color="info"
                        role="button"
                        href="/contact"
                        style={{fontSize: 20}}
                        className="btn-round ml-2"
                    >
                        יצירת קשר
                    </Button>
                </Link>
            </div>
            <Carousel />
          </Container>
        </div>
    </Layout>
  );
}

export default ProfilePage;
