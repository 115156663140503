import { Container } from 'reactstrap';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setInvitationType } from '../../store/features/invitations-page';

import fullLogo from "assets/img/laerua-full-logo.svg";

import Layout from '../layouts';

import Images from '../index-sections/Images';
import BasicElements from '../index-sections/BasicElements';
import Navbars from '../index-sections/Navbars';
import Tabs from '../index-sections/Tabs';
import Pagination from '../index-sections/Pagination';
import Notifications from '../index-sections/Notifications';
import Typography from '../index-sections/Typography';
import Javascript from '../index-sections/Javascript';
import Carousel from '../index-sections/Carousel';
import NucleoIcons from '../index-sections/NucleoIcons';
import CompleteExamples from '../index-sections/CompleteExamples';
import SignUp from '../index-sections/SignUp';
import Invitations from '../index-sections/Invitations';
import Download from '../index-sections/Download';

const gifConfig = require('assets/gif/config.json');

const InvitationsInstruction = () => {
    const { step } = useSelector(state => state.invitationsPage);
    if (step === 1) {
        return (
            <>
                <h1 className='h1-seo'>בחירת תבנית עיצוב</h1>
                <h2>לחצו על התמונה האינטרקטיבית כדי לבחור את תבנית סרטון ההזמנה. על מנת להתקדם לשלב בחירת השיר, לחצו "<strong>הבא</strong>".</h2>
            </>
        );
    }
    if (step === 2) {
        return (
            <>
                <h1 className='h1-seo'>בחירת שיר לסרטון</h1>
                <h4>בחרו שיר מרשימת השירים שלנו. עבור שיר שאינו ברשימה, רשמו לנו את שם השיר והמבצע, ואת הדקה בה להתחיל לנגנו. על מנת להתקדם לשלב מילוי פרטי האירוע, לחצו "<strong>הבא</strong>".</h4>
            </>
        );
    }
    if (step === 3) {
        return (
            <>
                <h1 className='h1-seo'>מילוי פרטי האירוע</h1>
                <h2>הכניסו את פרטי מיקום האירוע, תאריך ושעות, ושמות בעלי השמחה. על מנת להתקדם לסיכום ההזמנה, לחצו "<strong>הבא</strong>".</h2>
            </>
        );
    }
    if (step === 4) {
        return (
            <>
                <h1 className='h1-seo'>סיכום ההזמנה</h1>
                <h2>וודאו כי הפרטים שהכנסתם נכונים. במידה וכן, עברו לתשלום. כשעה מרגע קבלת התשלום, סרטון ההזמנה אצלכם בוואטספ. מזל טוב! <small style={{fontSize: 22}}>🥳</small></h2>
            </>
        );
    }
    return null;
};

const InvitationsPage = () => {
    const dispatch = useDispatch();
    const { invitationCategory = '' } = useParams();

    useEffect(function onInvitationsCategoryChange() {
        dispatch(setInvitationType(invitationCategory));
    }, [invitationCategory]);

    if (!gifConfig[invitationCategory]) {
        return null;
    }

    return (
        <Layout>
            <div className="main">
            <div 
                id="invitation" className="section invitations-section page-header text-center" 
                style={{ backgroundImage: `url(${require('assets/img/bg-red.jpeg').default})` }}
            >
                <Container>
                    <div className='content-center brand'>
                        <img alt='Laerua Logo' className='n-logo' src={fullLogo} />
                        <InvitationsInstruction />
                    </div>
                    <Invitations />
                </Container>
            </div>

            {/* <Images /> */}
            {/* <BasicElements /> */}
            {/* <Navbars /> */}
            {/* <Tabs /> */}
            {/* <Pagination /> */}
            {/* <Notifications /> */}
            {/* <Typography /> */}
            {/* <Javascript /> */}
            {/* <Carousel /> */}
            {/* <NucleoIcons /> */}
            {/* <CompleteExamples /> */}
            {/* <SignUp /> */}
            {/* <Download /> */}
            </div>
        </Layout>
    );
};

export default InvitationsPage;
