import React from "react";
import { Container } from "reactstrap";
import { Link } from 'react-router-dom';

const DefaultFooter = () => (
  <Container>
  <footer className="footer footer-default">
    <nav>
      <ul>
        <li>
          <a href="/" target="_blank">
            יצירת הזמנה
          </a>
        </li>
        <li>
          <Link to="/invitations/wedding" title="צפייה בסוגי הזמנות">
            קטלוג הזמנות
          </Link>
        </li>
        <li>
          <Link to="/pricing" title="מחירון">
            מחירון
          </Link>
        </li>
        <li>
          <Link to="/contact" title="יצירת קשר">
            יצירת קשר
          </Link>
        </li>
      </ul>
    </nav>

    <div className="copyright" id="copyright">
      <a href="/" title="לאירוע">
        ״לאירוע״ - 
      </a>
      &nbsp;
      כל הזכויות שמורות
      <small style={{fontSize: 14, marginRight: 5}}>{new Date().getFullYear()} ©</small>
    </div>
  </footer>
  </Container>
);

export default DefaultFooter;
